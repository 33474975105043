import * as React from "react"
import { useEffect } from "react"
import Cookies from 'universal-cookie';

const slideDown = (target, duration=500) => {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    //target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout( () => {  
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
      //alert("!");
    }, duration);
}

const slideUp = (target, duration=500) => {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none') {
        display = 'flex';
    }

    target.style.display = display;
    console.log('target.style.display = ', target.style.display)
    let height = target.offsetHeight;
    console.log('height = ', height)
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    //target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
}

const handleCloseBtn = e => {
    const cookies = new Cookies();

    let donationBar = document.querySelector('.donation-bar');
    slideDown(donationBar);

    cookies.set('donation-bar-state', 'closed', {
        maxAge: 86400,
        path: '/'
    }); // Max-Age = 86400 = 1 day
}

export default function DonationBar() {
    useEffect(() => {
        const cookies = new Cookies();
        let donationBar = document.querySelector('.donation-bar');
        
            window.addEventListener('scroll', (event) => {
                let donationBarStateCookieVal = cookies.get('donation-bar-state');
                
                if (donationBarStateCookieVal !== 'closed') {
                    let scrollYPlusInnerHeight = window.scrollY + window.innerHeight;
                    //console.log('scrollYPlusInnerHeight = ', scrollYPlusInnerHeight);
                    if (scrollYPlusInnerHeight > window.innerHeight) {
                        let donationBarDisplay = window.getComputedStyle(donationBar).display;
        
                        console.log('donationBarDisplay = ', donationBarDisplay);
                        if (donationBarDisplay === 'none') {
                            console.log('about to call slideUp');
                            slideUp(donationBar);
                        }
                    } else {
                        let donationBarDisplay = window.getComputedStyle(donationBar).display;
        
                        console.log('donationBarDisplay = ', donationBarDisplay);
                        if (donationBarDisplay === 'flex') {
                            console.log('about to call slideDown');
                            slideDown(donationBar);
                        }
                    }
                }
            });

        //slideDown(donationBar);
        //slideUp(donationBar);
      }, []);

    return (
        // TODO: fix spacing
        <div className="donation-bar bg-blue-bgMed bottom-0 fixed flex flex-row pt-10 pb-10 px-4 xl:px-0 shadow-donation-bar text-white w-full z-10">
            <div className="donation-bar-inner flex flex-col md:flex-row max-w-7xl mx-auto w-full">
                <div className="donation-bar-text flex flex-col mb-4 md:mr-4 w-full md:w-2/5 ">
                    <h2 className="font-bold text-2xl">Make a donation to help re-elect John Goncalves! Every dollar fuels our grassroots campaign.</h2>
                </div>
                <div className="donation-bar-ctas-phone flex sm:hidden flex-row w-full">
                    <a
                        href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=28"
                        type="button"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        $28
                    </a>
                    <a
                        href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=50"
                        type="button"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        $50
                    </a>
                    <a
                        href="https://secure.actblue.com/donate/friends-of-john-goncalves"
                        type="button"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        OTHER
                    </a>
                </div>
                <div className="donation-bar-ctas-tablet-desktop hidden sm:flex flex-col w-full md:w-3/5">
                    <div className="flex flew-row flex-wrap items-center justify-between">
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=28"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            $28
                        </a>
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=50"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            $50
                        </a>
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=100"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            $100
                        </a>
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=250"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            $250
                        </a>
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves?amount=500"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            $500
                        </a>
                        <a
                            href="https://secure.actblue.com/donate/friends-of-john-goncalves"
                            type="button"
                            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            OTHER
                        </a>
                    </div>
                </div>
            </div>
            <button className="donation-bar-close-btn absolute cursor-pointer h-2 p-0 w-2" onClick={handleCloseBtn}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    )
}
