import * as React from "react"
import { useEffect } from "react"
import jsonp from 'jsonp';

/**
 * Make a jsonp request to user's mailchimp list
 *  `param` object avoids CORS issues
 *  timeout to 3.5s so user isn't waiting forever
 *  usually occurs w/ privacy plugins enabled
 *  3.5s is a bit longer than the time it would take on a Slow 3G connection
 *
 * @param {String} url - concatenated string of user's gatsby-config.js
 *  options, along with any MC list fields as query params.
 *
 * @return {Promise} - a promise that resolves a data object
 *  or rejects an error object
 */

 const subscribeEmailToMailchimp = ({ url, timeout }) =>
  new Promise((resolve, reject) =>
      jsonp(url, { param: 'c', timeout }, (err, data) => {
          if (err) reject(err);
          if (data) resolve(data);
      }),
 );

/**
* Build a query string of MC list fields
*
* @param {Object} fields - a list of mailchimp audience field labels
*  and their values. We uppercase because that's what MC requires.
*  NOTE: GROUPS stay as lowercase (ex: MC uses group field names as `group[21269]`)
*
* @return {String} - `&FIELD1=value1&FIELD2=value2&group[21265][2]=group1`
*/
const convertListFields = fields => {
  let queryParams = '';
  for (const field in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, field)) {
          // If this is a list group, not user field then keep lowercase, as per MC reqs
          // https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/README.md#groups
          const fieldTransformed =
              field.substring(0, 6) === 'group[' ? field : field; // NOT UPPERCASING ANYMORE
          queryParams = queryParams.concat(`&${fieldTransformed}=${fields[field]}`);
      }
  }
 return queryParams;
};

/**
* Subscribe an email address to a Mailchimp email list.
* We use ES5 function syntax (instead of arrow) because we need `arguments.length`
*
* @param {String} email - required; the email address you want to subscribe
* @param {Object} fields - optional; add'l info (columns) you want included w/ this subscriber
* @param {String} endpointOverride - optional; if you want to override the default MC mailing list
*  that's listed in your gatsby-config, pass the list in here
*
* @return {Object} -
*  {
*    result: <String>(`success` || `error`)
*    msg: <String>(`Thank you for subscribing!` || `The email you entered is not valid.`),
*  }
*/
const addToMailchimp = function addToMailchimp(email, fields, endpointOverride) {
  //const isEmailValid = validate(email);
  const isEmailValid = true; // testing
  const emailEncoded = encodeURIComponent(email);
  if (!isEmailValid) {
      return Promise.resolve({
          result: 'error',
          msg: 'The email you entered is not valid.',
      });
 }

 let endpoint = 'https://johnforward1.us4.list-manage.com/subscribe/post?u=627d403ab5671dae64834341c&amp;id=5deb780900'; // eslint-disable-line no-undef
 const timeout = 3500; // eslint-disable-line no-undef

 // The following tests for whether you passed in a `fields` object. If
 // there are only two params and the second is a string, then we can safely
 // assume the second param is a MC mailing list, and not a fields object.
 if (arguments.length < 3 && typeof fields === 'string') {
     endpoint = fields;
 } else if (typeof endpointOverride === 'string') {
     endpoint = endpointOverride;
 }

 // Generates MC endpoint for our jsonp request. We have to
 // change `/post` to `/post-json` otherwise, MC returns an error
 endpoint = endpoint.replace(/\/post/g, '/post-json');
 const queryParams = `&EMAIL=${emailEncoded}${convertListFields(fields)}`;
 const url = `${endpoint}${queryParams}`;

 return subscribeEmailToMailchimp({ url, timeout });
};

const onFormSubmit = e => {
  e.preventDefault();

  let data = new FormData(e.target),
      obj = {};

  for (var key of data.keys()) {
    obj[key] = data.get(key);
  }

  console.log('obj = ', obj); // testing

  let listFields = {
    FNAME: obj['FNAME'],
    LNAME: obj['LNAME'],
    EMAIL: obj['EMAIL'],
    PHONE: obj['PHONE'],
    ZIPCODE: obj['ZIPCODE'],
    tags: obj['tags']
  }

  addToMailchimp(obj['EMAIL'], listFields)
  .then(data => {
    // I recommend setting data to React state
    // but you can do whatever you want (including ignoring this `then()` altogether)
    console.log(data)

    let footerMailchimpForm = document.querySelector('.footer-mailchimp-form'),
        footerMailchimpSuccess = document.querySelector('.footer-mailchimp-success'),
        footerMailchimpAlreadySubscribed = document.querySelector('.footer-mailchimp-already-subscribed'),
        footerMailchimpError = document.querySelector('.footer-mailchimp-error');

    fadeOut(footerMailchimpForm);

    if (data.result === 'success') {
      fadeIn(footerMailchimpSuccess);
    } else {
      if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
        fadeIn(footerMailchimpAlreadySubscribed);
      } else {
        fadeIn(footerMailchimpError);
      }
    }
  })
  .catch(() => {
    // unnecessary because Mailchimp only ever
    // returns a 200 status code
    // see below for how to handle errors
  })
}

// fade out
const fadeOut = function fadeOut(el){
  el.style.opacity = 1;

  (function fade() {
    if ((el.style.opacity -= .1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}

// fade in
const fadeIn = function fadeIn(el, display){
  el.style.opacity = 0;
  el.style.display = display || "block";

  (function fade() {
    var val = parseFloat(el.style.opacity);
    var proceed = ((val += 0.1) > 1) ? false : true;
    
    if (proceed) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const navigation = {
    meetJohn: [
      { name: 'Issues', href: '/issues' },
      { name: 'Meet John', href: '/meet-john' },
    ],
    media: [
      { name: 'Endorsements', href: '/media/endorsements' },
      { name: 'Photos', href: '/media/photos' },
      { name: 'Press', href: '/media/press' },
    ],
    getInvolved: [
      { name: 'Donate', href: 'https://secure.actblue.com/donate/friends-of-john-goncalves' },
      { name: 'Volunteer', href: '/volunteer' },
    ],
    contact: [
      { name: 'General contact: info@johnforward1.com', href: 'mailto:info@johnforward1.com' },
      { name: 'Press inquiries: press@johnforward1.com', href: 'mailto:press@johnforward1.com' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/JohnForPVD',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/johngpvd',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://www.facebook.com/JohnForPVD',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
    ],
  }
  
  export default function Footer() {
    useEffect(() => {
      /** FB Messenger - Chat Plugin - BEGIN **/
      var chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute("page_id", "110534837143289");
      chatbox.setAttribute("attribution", "biz_inbox");

      // FB SDK
      window.fbAsyncInit = function() {
        let FB = window.FB || {};

        if (typeof FB !== undefined) {
          FB.init({
            xfbml            : true,
            version          : 'v13.0'
          });
        }
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      /** FB Messenger - Chat Plugin - END **/
    })

    return (
      <footer className="bg-blue-bgDark" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 xl:px-0 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Meet John</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.meetJohn.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-300 hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Media</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.media.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-300 hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Get Involved</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.getInvolved.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-300 hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Contact</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.contact.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-300 hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-mailchimp-form mt-8 xl:mt-0">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Subscribe to our newsletter
              </h3>
              <p className="mt-4 text-base text-gray-300">
              Sign up for newsletter updates &amp; to join John's grassroots team!
              </p>
              <form
                className="mt-3 text-left text-white"
                method="POST"
                action="#"
                onSubmit={onFormSubmit}
              >
                <input type="hidden" name="tags" value="4531209, 4531231"></input> {/* Tags: Website, Website - Sitewide - Footer */}
                <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                  <div>
                    <label htmlFor="first-name" className="block text-sm font-medium">
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="FNAME"
                        id="first-name"
                        autoComplete="given-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-sm font-medium">
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="LNAME"
                        id="last-name"
                        autoComplete="family-name"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium">
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="EMAIL"
                        type="email"
                        autoComplete="email"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium">
                      Mobile Phone Number (optional)
                    </label>
                    <div className="mt-1">
                      {/* TODO: validate this following https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/tel.*/ }
                      <input
                        id="tel"
                        name="PHONE"
                        type="tel"
                        autoComplete="tel"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-4 gap-x-4">
                  <div className="col-span-1">
                    <label htmlFor="zip-code" className="block text-sm font-medium">
                      ZIP Code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="ZIPCODE"
                        id="zip-code"
                        autoComplete="zip-code"
                        className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-blue-500 border-gray-300 rounded-md text-black"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-2 pt-6">
                    <button
                    type="submit"
                    className="w-full h-full border border-transparent text-base font-medium rounded-md text-white bg-orange-button shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:items-center"
                    >
                      Join Our Movement
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="footer-mailchimp-success text-white hidden">
              <p className="mt-3 max-w-md mx-auto text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                Thank you for joining our organization!
              </p>
              <p className="mt-3 max-w-md mx-auto text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                Make a donation to help re-elect John Goncalves! Every dollar fuels our grassroots campaign.
              </p>
              <div className="footer-mailchimp-success-donation-ctas mt-3 sm:flex flex-col w-full">
                <div className="flex flew-row flex-wrap items-center justify-between">
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      $28
                  </button>
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      $50
                  </button>
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      $100
                  </button>
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      $250
                  </button>
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      $500
                  </button>
                  <button
                      type="button"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-bold rounded-md shadow-sm text-white bg-orange-button hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  >
                      OTHER
                  </button>
                </div>
              </div>
              <aside>
                By clicking the links above, you certify that you are responsible for paying all charges incurred in using the debit or credit card to be charged, and that your personal funds will be the true source of the contribution.
              </aside>
            </div>
            <div className="footer-mailchimp-already-subscribed text-white hidden">
              <p className="mt-3 max-w-md mx-auto text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                Already subscribed&mdash;thank you!
              </p>
            </div>
            <div className="footer-mailchimp-error text-red hidden">
              <p className="mt-3 max-w-md mx-auto text-lg sm:text-xl md:mt-5 md:max-w-3xl">
                Error joining&mdash;please try again.
              </p>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; 2022 Friends of John Goncalves. All rights reserved. | <a className="text-gray-300 hover:text-white" href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
        </div>

        {/* FB Messenger - Chat Plugin */}
        <div id="fb-root"></div>
        <div id="fb-customer-chat" className="fb-customerchat">
        </div>
      </footer>


    )
  }
